'use client'
import { FunctionComponent, useContext, useEffect } from 'react'
import { ConfigContext, useTranslation } from '../../context/ConfigContext'
import useCurrentLocale from '../../../core/hook/useCurrentLocale'

const ZENDESK_KEY = '0cf06b76-1da2-466a-a7d7-25c72a90dcfe'

declare global {
  /* eslint-disable no-unused-vars */
  interface Window {
    zE: any
    zESettings?: any
  }
}

const Zendesk = () => {
  const t = useTranslation()
  const locale = useCurrentLocale()
  if (typeof window !== 'undefined' && typeof window.zESettings === 'undefined') {
    window.zESettings = {
      webWidget: {
        contactForm: {
          selectTicketForm: {
            '*': t('What is your request ?'),
          },
        },
        chat: {
          concierge: {
            title: {
              '*': t('What is your request ?'),
            },
          },
        },
        position: {
          horizontal: locale.indexOf('ar') >= 0 ? 'left' : 'right',
        },
      },
    }
  }

  useEffect(() => {
    let mounted = true
    let script = document.querySelector('#ze-snippet') as HTMLScriptElement
    if (!script) {
      script = document.createElement('script')
      script.id = 'ze-snippet'
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_KEY}`
      document.head.appendChild(script)
    }
    if (!window.zE) {
      script.addEventListener('load', function () {
        if (mounted) {
          window.zE('webWidget', 'show')
        }
      })
    } else {
      window.zE('webWidget', 'show')
    }

    return () => {
      mounted = false
      if (window.zE) {
        window.zE('webWidget', 'hide')
      }
    }
  }, [])

  return null
}

export const ZendeskWidget: FunctionComponent = () => {
  const { isEcommerce } = useContext(ConfigContext)

  if (!isEcommerce()) {
    return null
  }

  return <Zendesk />
}
